import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const isHtmlDefined = (html) => (html && html.length > 10 ? html : null);

const InfoText = (props) => {
  const { data, location } = props;
  const { primary } = data;
  const { title, subtitle, content } = primary;

  const productVariant = location?.pathname?.includes('product') ? styles.productVariant : '';

  // content checks
  const titleHtml = isHtmlDefined(title?.html);
  const contentHtml = isHtmlDefined(content?.html);
  const subtitleHtml = isHtmlDefined(subtitle?.html);

  return (
    <Section sliceName="InfoText" className={`info-text ${styles.infoText} ${productVariant}`}>
      <div className={styles.textContainer}>
        {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {subtitleHtml && <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitleHtml }} />}
        {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      </div>
    </Section>
  );
};

export default InfoText;
